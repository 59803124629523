<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("partner.informationPartner") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button v-show="edit" class="btn btn-secondary">
          {{ $t("general.save") }}
        </button>
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'adminPartners' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button v-show="!edit" class="btn btn-primary" @click="savePartner">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">{{ $t("partner.baseData") }}:</h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.number") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <input
                v-model="partner.number"
                type="text"
                class="form-control"
                readonly
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fal fa-lock icon-lg"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.active") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <span class="switch switch-sm">
              <label>
                <input v-model="partner.active" type="checkbox" />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.uuid") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-copy" />
                </span>
              </div>
              <input
                v-model="partner.uuid"
                type="text"
                class="form-control pr-0"
                readonly
                @click="copyText(partner.uuid)"
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fal fa-lock icon-lg"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.apiToken") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-copy" />
                </span>
              </div>
              <input
                v-model="partner.api_token"
                type="text"
                class="form-control pr-0"
                readonly
                @click="copyText(partner.api_token)"
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fal fa-lock icon-lg"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">{{ $t("partner.companyData") }}:</h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.logo") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <ImageUploadCropper
              aspect-ratio=""
              :default-image="companyLogo"
              :upload-button-text="$t('companyData.uploadLogo')"
              :crop-button-text="$t('companyData.cropLogo')"
              :remove-button-text="$t('companyData.removeLogo')"
              @cropMode="edit = $event"
              @fileUpdated="files = $event"
              @fileRemoved="fileRemoved()"
            >
            </ImageUploadCropper>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.name") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input v-model="partner.name" type="text" class="form-control" />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.nameShort") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="partner.name_short"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.language") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <v-select
                v-model="partner.language_id"
                class="form-control"
                :items="languageOptions"
                item-text="label"
                item-value="id"
                :menu-props="{ offsetY: true }"
              />
              <div class="input-group-append">
                <span class="input-group-text border-0">
                  <i class="fal fa-earth-europe icon-lg" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label mb-md-3">
            {{ $t("partner.contactPerson") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <v-select
                v-model="partner.main_contact_id"
                class="form-control"
                :items="contactPersons"
                item-text="name"
                item-value="id"
                :menu-props="{ offsetY: true }"
              />
              <div class="input-group-append">
                <span class="input-group-text border-0">
                  <i class="fal fa-user-tie icon-lg" />
                </span>
              </div>
            </div>
            <span class="form-text text-muted">{{
              $t("partner.contactPersonYedi")
            }}</span>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import Partners from "@/components/Admins/Partners/partners";

import Languages from "@/components/General/Languages/languages";
import Admin from "@/components/Admins/Account/Admin/admins";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Media from "@/components/Admins/Media/media";
import ImageUploadCropper from "@/components/Admins/Media/ImageUploadCropper";
import Swal from "sweetalert2";
import { bus } from "@/main";

export default {
  components: {
    ImageUploadCropper
  },
  data() {
    return {
      files: [],
      edit: false,
      companyLogo: "",
      partner: {},
      companyData: {},
      languageOptions: [],
      contactPersons: [],
      isBusy: false,
      oldLogoId: null
    };
  },
  mounted() {
    this.getLanguages();
    this.loadPartner();
    this.getContactPersons();
  },
  methods: {
    loadPartner() {
      let self = this;
      this.isBusy = true;
      let partnerId = this.$route.params.id;
      Partners.get(partnerId)
        .then(response => {
          this.partner = response.data.data;
          if (this.partner.language !== null) {
            this.partner.language_id = this.partner.language.id;
          }
          if (this.partner.logo_id !== null) {
            self.oldLogoId = this.partner.logo_id;
            Media.get(this.partner.logo_id)
              .then(mediaResponse => {
                self.companyLogo = mediaResponse.data.data.link;
              })
              .catch(error => {
                this.$swal.fire({
                  title: this.$t("general.caution"),
                  text: error.response.data.message,
                  icon: "error"
                });
              });
          }

          this.isBusy = false;
          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: this.$t("menu.adminPartners"),
              route: ""
            },
            {
              title: this.$t("general.overview"),
              route: "/admin/partners"
            },
            {
              title: this.partner.name,
              route: "/admin/partners/" + self.partner.id
            }
          ]);
        })
        .catch(e => {
          console.log(e);
          this.isBusy = false;
        });
    },
    async savePartner() {
      this.isBusy = true;
      let self = this;
      let oldLogoId = self.oldLogoId;

      if (this.files.length) {
        let mediaData = new FormData();
        mediaData.append("files[]", this.files[0].file);
        mediaData.append("description[]", "Logo - " + self.partner.name);
        await Media.store(mediaData)
          .then(response => {
            if (response.data.data.success) {
              self.partner.logo_id = response.data.data.uploaded[0].id;
            }
          })
          .catch(error => {
            Swal.fire({
              title: this.$t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
            this.isBusy = false;
          });
      }

      Partners.update(self.partner.id, self.partner)
        .then(() => {
          if (this.files.length || !this.partner.logo_id) {
            self.deleteLogo(oldLogoId);
          }

          this.loadPartner();
          this.$toast.fire({
            icon: "success",
            title: this.$t("partner.partnerUpdated")
          });
          bus.$emit("partnerUpdated");
          bus.$emit("reloadHeaderFilterPartner");
          this.isBusy = false;
        })
        .catch(error => {
          self.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          self.isBusy = false;
        });
    },
    deleteLogo(logoId = this.partner.logo_id) {
      if (logoId !== null) {
        Media.delete(logoId);
      }
    },
    fileRemoved() {
      this.files = [];
      this.companyLogo = false;
      this.partner.logo_id = null;
    },
    getLanguages() {
      Languages.getAll()
        .then(response => {
          this.languageOptions = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    getContactPersons() {
      const params = { size: 0 };
      Admin.getAll(params)
        .then(response => {
          this.contactPersons = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#profile-image {
  background-image: url(~@/assets/media/users/blank.png);

  .image-input-wrapper {
    background-size: contain;
    background-position: center;
  }

  .icon-undo {
    font-size: 0.8rem;
  }
}
</style>
