<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("partner.billing") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'adminPartners' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="saveData">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">{{ $t("partner.billing") }}:</h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.billingAddress1") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="partner.billing_information.addressLine1"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.billingAddress2") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="partner.billing_information.addressLine2"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.billingAddress3") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="partner.billing_information.addressLine3"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.billingStreet") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="partner.billing_information.street"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.billingZipcode") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="partner.billing_information.zipcode"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.billingCity") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="partner.billing_information.city"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.billingCountry") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <v-select
                v-model="partner.billing_information.country"
                class="form-control"
                :items="countries"
                item-text="label_german"
                item-value="id"
                :menu-props="{ offsetY: true }"
              />
              <div class="input-group-append">
                <span class="input-group-text border-0">
                  <i class="fal fa-flag icon-lg" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Partner from "@/components/Admins/Partners/partners";
import Countries from "@/components/Admins/Settings/System/Countries/countries";

export default {
  data() {
    return {
      partner: {
        billing_information: {}
      },
      isBusy: false,
      countries: []
    };
  },
  mounted() {
    this.getPartner();
    this.getCountries();
  },
  methods: {
    getPartner() {
      this.isBusy = true;
      let partnerId = this.$route.params.id;
      Partner.get(partnerId)
        .then(response => {
          let partner = response.data.data;
          this.validateProps(partner);
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    getCountries() {
      const params = {
        size: 999
      };
      Countries.getAll(params)
        .then(response => {
          this.countries = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    saveData() {
      this.isBusy = true;
      const data = this.partner;
      let partnerId = this.$route.params.id;
      Partner.update(partnerId, data)
        .then(response => {
          let partner = response.data.data;
          this.validateProps(partner);
          this.$toast.fire({
            icon: "success",
            title: this.$t("partner.partnerUpdated")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    validateProps(partner) {
      if (!partner.billing_information) partner.billing_information = {};
      this.partner = partner;
    }
  }
};
</script>
