<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("partner.contactData") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'adminPartners' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="saveData">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">{{ $t("partner.contactData") }}:</h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.contactWebsite") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-link icon-lg" />
                </span>
              </div>
              <input
                v-model="partner.additional_data.contact.website"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.contactEmail") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-at icon-lg" />
                </span>
              </div>
              <input
                v-model="partner.additional_data.contact.email"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.contactPhone") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-phone icon-lg" />
                </span>
              </div>
              <input
                v-model="partner.additional_data.contact.phone"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.contactFax") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-fax icon-lg" />
                </span>
              </div>
              <input
                v-model="partner.additional_data.contact.fax"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Partner from "@/components/Admins/Partners/partners";

export default {
  data() {
    return {
      partner: {
        additional_data: {
          contact: {}
        }
      },
      isBusy: false
    };
  },
  mounted() {
    this.getPartner();
  },
  methods: {
    getPartner() {
      this.isBusy = true;
      let partnerId = this.$route.params.id;
      Partner.get(partnerId)
        .then(response => {
          let partner = response.data.data;
          this.validateProps(partner);
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    saveData() {
      this.isBusy = true;
      const data = this.partner;
      let partnerId = this.$route.params.id;
      Partner.update(partnerId, data)
        .then(response => {
          let partner = response.data.data;
          this.validateProps(partner);
          this.$toast.fire({
            icon: "success",
            title: this.$t("partner.partnerUpdated")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    validateProps(partner) {
      if (!partner.additional_data) partner.additional_data = { contact: {} };
      if (!partner.additional_data.contact)
        partner.additional_data.contact = {};
      this.partner = partner;
    }
  }
};
</script>
