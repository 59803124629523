<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-body pt-4">
            <div class="d-flex justify-content-end"></div>
            <div class="row py-3">
              <div class="col-md-4 col-12 d-flex align-items-center">
                <div class="symbol symbol-60 symbol-lg-70 details-symbol">
                  <div
                    class="symbol-label symbol-label-profile"
                    :style="
                      companyLogo
                        ? 'background-image: url(' +
                          companyLogo +
                          '); background-color: #ffffff;'
                        : ''
                    "
                  ></div>
                </div>
              </div>
              <div class="col-md-8 col-12 row align-items-center">
                <div class="col-12">
                  <div class="font-weight-bolder font-size-h5 text-dark-75">
                    <b-skeleton
                      v-if="!partner.id"
                      type="text"
                      height="1.5rem"
                    />
                    <span>{{ partner.name }}</span>
                  </div>
                  <div class="text-muted">
                    <b-skeleton v-if="!partner.id" type="text" width="60%" />
                    <span>{{ partner.number }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mb-2"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="{ name: item.route }"
                  custom
                >
                  <a
                    :href="href"
                    class="navi-link py-4"
                    :class="{ active: isExactActive }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8 partner-details">
        <partnerInformation
          v-if="$route.name === 'adminPartnersDetails'"
          @partner-updated="loadPartner"
        />
        <partnerContact
          v-else-if="$route.name === 'adminPartnersDetailsContact'"
        />
        <partnerBilling
          v-else-if="$route.name === 'adminPartnersDetailsBilling'"
        />
        <partnerAccounting
          v-else-if="$route.name === 'adminPartnersDetailsAccounting'"
        />
        <partnerStorage
          v-else-if="$route.name === 'adminPartnersDetailsStorage'"
        />
        <partnerDatabases
          v-else-if="$route.name === 'adminPartnersDetailsDatabases'"
        />
        <partnerLoginSecurity
            v-else-if="$route.name === 'adminPartnersDetailsSecurity'"
        />
      </div>
    </div>
  </div>
</template>

<style>
.disabledTab {
  display: none;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Partners from "@/components/Admins/Partners/partners";
import partnerInformation from "@/components/Admins/Partners/Details/Information";
import partnerContact from "@/components/Admins/Partners/Details/Contact";
import partnerBilling from "@/components/Admins/Partners/Details/Billing";
import partnerAccounting from "@/components/Admins/Partners/Details/Accounting";
import partnerStorage from "@/components/Admins/Partners/Details/Storage";
import partnerDatabases from "@/components/Admins/Partners/Details/Databases";
import partnerLoginSecurity from "@/components/Admins/Partners/Details/LoginSecurity";
import { bus } from "@/main";
import Media from "@/components/Admins/Media/media";
import { mapGetters } from "vuex";

export default {
  components: {
    partnerInformation,
    partnerContact,
    partnerBilling,
    partnerAccounting,
    partnerStorage,
    partnerDatabases,
    partnerLoginSecurity
  },
  data() {
    return {
      partner: {},
      companyLogo: "",
      tabIndex: 0,
      tabNavigation: [
        {
          route: "adminPartnersDetails",
          label: "partner.informationPartner",
          icon: "fal fa-user"
        },
        {
          route: "adminPartnersDetailsContact",
          label: "partner.contactData",
          icon: "fal fa-paper-plane"
        },
        {
          route: "adminPartnersDetailsBilling",
          label: "partner.billing",
          icon: "fal fa-receipt"
        },
        {
          route: "adminPartnersDetailsAccounting",
          label: "partner.accounting",
          icon: "fal fa-book-open"
        },
        {
          route: "adminPartnersDetailsStorage",
          label: "partner.storage",
          icon: "fal fa-cube"
        },
        {
          route: "adminPartnersDetailsDatabases",
          label: "partner.databases",
          icon: "fal fa-layer-group"
        },
        {
          route: "adminPartnersDetailsSecurity",
          label: "partner.loginSecurity",
          icon: "fal fa-shield"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["selectedPartner"])
  },
  watch: {
    selectedPartner: function () {
      this.loadPartner();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Partner Details" }]);
    if (this.$route.params.id !== toString(this.partner.id)) this.loadPartner();
    bus.$on("partnerUpdated", this.loadPartner);
  },
  destroyed() {
    bus.$off("partnerUpdated", this.loadPartner);
  },
  methods: {
    loadPartner() {
      let self = this;
      this.partner = {};
      let partnerId = this.$route.params.id;
      Partners.get(partnerId)
        .then(response => {
          this.partner = response.data.data;

          if (this.partner.logo_id !== null) {
            Media.get(this.partner.logo_id)
              .then(mediaResponse => {
                self.companyLogo = mediaResponse.data.data.link;
              })
              .catch(error => {
                this.$swal.fire({
                  title: this.$t("general.caution"),
                  text: error.response.data.message,
                  icon: "error"
                });
              });
          } else {
            self.companyLogo = "";
          }

          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: this.$t("menu.adminPartners"),
              route: ""
            },
            {
              title: this.$t("general.overview"),
              route: "/admin/partners"
            },
            {
              title: this.partner.name,
              route: "/admin/partners/" + this.partner.id
            }
          ]);
        })
        .catch(e => {
          console.log(e);
        });
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  }
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/_functions";
@import "node_modules/bootstrap/scss/_variables";
@import "node_modules/bootstrap/scss/_mixins";
.partner-details {
  .col-form-label {
    @include media-breakpoint-down(lg) {
      justify-content: flex-start !important;
    }
  }
}
.symbol .symbol-label-details {
  background-image: url("~@/assets/media/users/blank.png");
  background-size: contain;
  background-position: center;
}
</style>
