<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("partner.storage") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'adminPartners' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="saveData">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <b-row>
          <b-col md="12">
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t("partner.storageType") }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-4">
                <div class="input-group">
                  <v-select
                    v-model="partner.tenant.tenantStorage.type"
                    class="form-control"
                    :items="storageTypes"
                    :item-text="item => $t(item.label)"
                    item-value="type"
                    :menu-props="{ offsetY: true }"
                    @change="selectStorageType"
                  />
                </div>
              </div>
            </div>

            <div
              v-for="(entry, key) in selectedStorageTypeData.config"
              :key="key"
              class="form-group row align-items-center"
            >
              <label class="col-xl-3 col-lg-3 col-form-label">
                {{ $t(entry.label) }}
              </label>
              <div class="col-md-9 col-lg-6 col-xl-4">
                <div v-if="entry.type !== 'boolean'" class="input-group">
                  <input
                    v-if="entry.type === 'text'"
                    v-model="partner.tenant.tenantStorage.config[key]"
                    type="text"
                    class="form-control"
                  />
                  <div
                    v-if="entry.type === 'password'"
                    class="input-group-prepend"
                    @click="copyText(partner.tenant.tenantStorage.config[key])"
                  >
                    <span class="input-group-text">
                      <i class="fal fa-copy cursor-pointer" />
                    </span>
                  </div>
                  <input
                    v-if="entry.type === 'password'"
                    v-model="partner.tenant.tenantStorage.config[key]"
                    :type="[showPassword ? 'text' : 'password']"
                    class="form-control"
                    autocomplete="on"
                  />
                  <div
                    v-if="entry.type === 'password'"
                    class="input-group-append"
                    @click="togglePassword()"
                  >
                    <span class="input-group-text">
                      <i
                        class="fal icon-lg cursor-pointer"
                        :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"
                      ></i>
                    </span>
                  </div>
                </div>
                <span v-if="entry.type === 'boolean'" class="switch switch-sm">
                  <label>
                    <input
                      v-model="partner.tenant.tenantStorage.config[key]"
                      type="checkbox"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </form>
  </div>
</template>

<script>
import Partner from "@/components/Admins/Partners/partners";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TenantStorage from "@/components/Admins/TenantStorage/tenantStorage";

export default {
  data() {
    return {
      partner: {
        tenant: {
          tenantStorage: {
            type: "ftp",
            config: {}
          }
        }
      },
      storageTypes: TenantStorage.getTypes(),
      showPassword: false,
      isBusy: false
    };
  },
  computed: {
    selectedStorageTypeData() {
      let returnData = {};
      this.storageTypes.forEach(entry => {
        if (entry.type === this.partner.tenant.tenantStorage.type) {
          returnData = entry;
        }
      });
      return returnData;
    }
  },
  mounted() {
    this.getPartner();
  },
  methods: {
    selectStorageType() {
      // this.partner.tenant.tenantStorage.type = type.type;
      this.partner.tenant.tenantStorage.config = {};
      for (const config in this.selectedStorageTypeData.config) {
        if (config === "port") {
          this.partner.tenant.tenantStorage.config[config] = "21";
        }
        if (this.selectedStorageTypeData.config[config].type === "boolean") {
          this.partner.tenant.tenantStorage.config[config] = false;
        }
      }
    },
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    getPartner() {
      this.isBusy = true;
      let partnerId = this.$route.params.id;
      Partner.get(partnerId)
        .then(response => {
          let partnerData = response.data.data;
          if (partnerData.tenant.tenantStorage === null) {
            partnerData.tenant.tenantStorage =
              this.partner.tenant.tenantStorage;
          }
          this.partner = partnerData;
          this.isBusy = false;
          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: this.$t("menu.adminTenants"),
              route: ""
            },
            {
              title: this.$t("general.overview"),
              route: "/admin/partners"
            },
            {
              title: this.partner.name,
              route: "/admin/partners/" + this.partner.id
            }
          ]);
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    saveData() {
      this.isBusy = true;
      const storageId = this.partner.tenant.tenantStorage.id;

      if (
        this.partner.tenant.tenantStorage.config.root === undefined ||
        this.partner.tenant.tenantStorage.config.root === null
      ) {
        this.partner.tenant.tenantStorage.config.root = "";
      }

      if (typeof storageId === "undefined") {
        this.create();
        return false;
      }
      const storageData = this.partner.tenant.tenantStorage;
      TenantStorage.update(storageId, storageData)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("partner.partnerUpdated")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    create() {
      this.isBusy = true;
      let storageData = this.partner.tenant.tenantStorage;
      storageData.tenant_id = this.partner.tenant.id;
      TenantStorage.store(storageData)
        .then(() => {
          this.getPartner();
          this.$toast.fire({
            icon: "success",
            title: this.$t("partner.partnerUpdated")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>
