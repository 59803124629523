<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("partner.databases") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'adminPartners' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="saveData">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <b-row>
          <b-col
            v-for="(entry, key) in partner.tenant.tenantConnections"
            :key="key"
            md="6"
          >
            <b-row>
              <b-col md="12">
                <h5 class="font-weight-bold d-inline mr-2">{{ entry.type }}</h5>
                <b
                  v-if="entry.type === 'mysql'"
                  class="btn btn-icon btn-light btn-sm mr-1"
                  @click="openDatabase()"
                >
                  <i class="fal fa-database"></i>
                </b>
              </b-col>
              <b-col md="12">
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("partner.databaseHost") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-6">
                    <div class="input-group">
                      <input
                        v-model="partner.tenant.tenantConnections[key].host"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="12">
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("partner.databasePort") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-6">
                    <div class="input-group">
                      <input
                        v-model="partner.tenant.tenantConnections[key].port"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="12">
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("partner.databaseName") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-6">
                    <div class="input-group">
                      <input
                        v-model="partner.tenant.tenantConnections[key].database"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="12">
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("partner.databaseUsername") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-6">
                    <div class="input-group">
                      <input
                        v-model="partner.tenant.tenantConnections[key].username"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="12">
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("partner.databasePassword") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-6">
                    <div class="input-group">
                      <div
                        class="input-group-prepend"
                        @click="
                          copyText(
                            partner.tenant.tenantConnections[key].password
                          )
                        "
                      >
                        <span class="input-group-text">
                          <i class="fal fa-copy cursor-pointer" />
                        </span>
                      </div>
                      <input
                        v-model="partner.tenant.tenantConnections[key].password"
                        :type="[
                          showPasswords[entry.type] ? 'text' : 'password'
                        ]"
                        class="form-control pr-0"
                      />
                      <div
                        class="input-group-append"
                        @click="togglePassword(entry.type)"
                      >
                        <span class="input-group-text">
                          <i
                            class="fal icon-lg cursor-pointer"
                            :class="[
                              showPasswords[entry.type]
                                ? 'fa-eye-slash'
                                : 'fa-eye'
                            ]"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </form>
  </div>
</template>

<script>
import Partner from "@/components/Admins/Partners/partners";
import TenantConnections from "@/components/Admins/TenantConnections/tenantConnections";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      partner: {
        tenant: {
          tenantConnections: []
        }
      },
      showPasswords: {
        mysql: false,
        mongodb: false
      },
      isBusy: false
    };
  },
  mounted() {
    this.getPartner();
  },
  methods: {
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    togglePassword(dbName) {
      this.showPasswords[dbName] = !this.showPasswords[dbName];
    },
    getPartner() {
      this.isBusy = true;
      let partnerId = this.$route.params.id;
      Partner.get(partnerId)
        .then(response => {
          this.partner = response.data.data;
          this.isBusy = false;
          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: this.$t("menu.adminTenants"),
              route: ""
            },
            {
              title: this.$t("general.overview"),
              route: "/admin/partners"
            },
            {
              title: this.partner.name,
              route: "/admin/partners/" + this.partner.id
            }
          ]);
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    saveData() {
      this.isBusy = true;
      const connections = this.partner.tenant.tenantConnections;
      connections.forEach(entry => {
        TenantConnections.update(entry.id, entry)
          .then(() => {
            this.isBusy = false;
          })
          .catch(error => {
            this.$swal.fire({
              title: this.$t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
            this.isBusy = false;
          });
      });
      this.$toast.fire({
        icon: "success",
        title: this.$t("partner.partnerUpdated")
      });
    },
    async openDatabase() {
      this.isBusy = true;
      let res = await Partner.checkDBAuth(this.partner);
      if (res.success) {
        window.open(res.url, "_blank");
        return;
      }
      this.$swal.fire({
        title: this.$t("partner.error"),
        text: this.$t("partner.couldNotOpenDatabase"),
        icon: "error"
      });
      this.isBusy = false;
    }
  }
};
</script>
