<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("partner.accounting") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'adminPartners' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="saveData">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">{{ $t("partner.accountingTax") }}:</h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.accountingHasVat") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <span class="switch switch-sm">
              <label>
                <input
                  v-model="partner.additional_data.accounting.hasVatNumber"
                  type="checkbox"
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div v-show="hasVat" class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.accountingVatNumber") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="partner.additional_data.accounting.vatNumber"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.accountingNoVat") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <span class="switch switch-sm">
              <label>
                <input
                  v-model="partner.additional_data.accounting.noVat"
                  type="checkbox"
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">
              {{ $t("partner.accountingInvoicing") }}:
            </h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.accountingInvoicesSendEmail") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <span class="switch switch-sm">
              <label>
                <input
                  v-model="partner.additional_data.accounting.invoicesSendEmail"
                  type="checkbox"
                />
                <span></span>
              </label>
            </span>
          </div>
        </div>
        <div v-show="sendEmail" class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.accountingInvoicesEmail") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="partner.additional_data.accounting.invoicesEmail"
              type="text"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Partner from "@/components/Admins/Partners/partners";
export default {
  data() {
    return {
      partner: {
        additional_data: {
          accounting: {}
        }
      },
      isBusy: false
    };
  },
  computed: {
    hasVat: function () {
      return !!this.partner.additional_data.accounting.hasVatNumber;
    },
    sendEmail: function () {
      return !!this.partner.additional_data.accounting.invoicesSendEmail;
    }
  },
  mounted() {
    this.getPartner();
  },
  methods: {
    getPartner() {
      this.isBusy = true;
      let partnerId = this.$route.params.id;
      Partner.get(partnerId)
        .then(response => {
          let partner = response.data.data;
          this.validateProps(partner);
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    saveData() {
      this.isBusy = true;
      const data = this.partner;
      let partnerId = this.$route.params.id;
      Partner.update(partnerId, data)
        .then(response => {
          let partner = response.data.data;
          this.validateProps(partner);
          this.$toast.fire({
            icon: "success",
            title: this.$t("partner.partnerUpdated")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    validateProps(partner) {
      if (!partner.additional_data)
        partner.additional_data = { accounting: {} };
      if (!partner.additional_data.accounting)
        partner.additional_data.accounting = {};
      this.partner = partner;
    }
  }
};
</script>
